<template>
	<div data-route>
		<page-header
			heading="My profile"
		/>
		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
				v-if="formLoaded"
			>
				<input-field
					name="First name"
					type="text"
					rules="required"
					v-model="profile.firstName"
				/>
				<input-field
					name="Last name"
					type="text"
					rules="required"
					v-model="profile.lastName"
				/>
				<input-field
					:readonly="true"
					name="Email"
					type="text"
					v-model="profile.email"
				/>
				<actions :actions="updateDetailsActions" />
				<select-field
					name="Your experience level"
					rules="required"
					v-if="profile.experienceLevel < 9"
					:options="experienceLevelOptions.slice(0,8)"
					v-model="profile.experienceLevel"
				/>
				<input-field
					name="Your experience level"
					type="text"
					:readonly="true"
					v-else=""
					v-model="experienceLevelOptions[profile.experienceLevel - 1].text"
				/>
				<select-field
					name="Your preferred bidding system"
					rules="required"
					:options="biddingSystemOptions"
					v-model="profile.biddingSystem"
				/>
				<actions
					:actions="actions"
					:isWaiting="isWaiting"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import PageHeader            from '@/components/ui/PageHeader';
	import InputField            from '@/components/forms/InputField';
	import SelectField           from '@/components/forms/SelectField';
	import Actions               from '@/components/ui/Actions';
	import actionClick           from '@/mixins/actionClick';
	import invalidFormMessage    from '@/mixins/invalidFormMessage';
	import api                   from '@/services/api';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems }    from '@/consts';

	export default {
		metaInfo: {
			title: 'My profile'
		},
		components: {
			PageHeader,
			InputField,
			SelectField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			isWaiting: undefined,
			experienceLevelOptions: experienceLevels,
			biddingSystemOptions: biddingSystems,
			profile: {
				email:            '',
				firstName:        '',
				lastName:         '',
				experienceLevel:  '',
				biddingSystem:    ''
			},
			formLoaded:         false,
			updateDetailsActions: {
				primary: [
					{
						text: 'Change email',
						route: '/my-profile/change-email'
					},
					{
						text: 'Change password',
						route: '/my-profile/change-password'
					}
				]
			},
			actions: {
				primary: [
					{
						text: 'Save',
						type: 'submit',
						btnId: 'submit'
					}
				],
				secondary: [
					{
						text: 'Cancel',
						route: '/'
					}
				]
			}
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/my-profile',
						text: 'My profile'
					}
				];
			}
		},
		created: function () {
			this.setProfile();
		},
		methods: {
			async setProfile () {
				const profile = await api.auth.getCurrentUserProfile();
				if (!profile) {
					this.$store.commit('ui/showError');
					return false;
				}
				// store profile...
				this.$store.commit('auth/setProfile', {
					profile
				});
				this.profile = {
					...this.profile,
					...profile
				};
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				this.isWaiting = 'submit';
				const profile = await api.auth.updateCurrentUserProfile({
					profile: {
						...this.profile,
						timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/London'
					}
				});
				if (!profile) {
					this.isWaiting = undefined;
					return false;
				}
				this.isWaiting = undefined;
				this.$store.commit('auth/setProfile', {
					profile
				});
				await this.$router.push('/browse-courses');
				this.$store.commit('ui/showNotification', {
					notification: 'Your profile has been updated'
				});
			}
		}
	};

</script>
